<template>
    <div class="container-xl" v-if="!$store.getters.isForbidden">
      <div class="row row-deck row-cards mb-3" v-if="balances_list.length > 0">
        <div class="col-sm-6 col-lg-3" v-for="(item, index) in balances_list" :key="index">
          <div class="card" v-loading="item.loading">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="subheader"></div>
              </div>
              <div class="d-flex mb-2">
                <div class="h1 mb-3">{{ item.current_balance }}</div>
                <div class="ml-2">
                  <a @click="refreshBalance(item, index)" href="javascript:;" style="vertical-align:middle; display: table-cell;"><font-awesome-icon icon="sync"/></a>
                </div>
              </div>
              <div class="d-flex mb-2">
                <div>{{ item.vendorName }} Balance</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card col-lg-12 mt-2">
        <div class="card-header border-0 justify-content-between">
          <div class="card-title">{{ $route.name }} List</div>
          <div class="flex flex-row mb-2 pr-2 float-right">
            <!-- <el-select size="small" v-model="search.product_status" class="mr-2">
              <el-option v-for="(item, index) in status_options" :key="index" :value="item.value" :label="item.label"/>
            </el-select> -->
            <el-date-picker
                v-model="search.date"
                @change="searchHandler"
                type="daterange"
                size="small"
                :picker-options="datePickerOptions"
                class="mr-2"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date">
              </el-date-picker>
            <!-- <el-button :loading="isLoading" type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button> -->
            <el-button :loading="isLoading" type="primary" @click="downloadHandler" size="small"><font-awesome-icon icon="download"/> Download </el-button>
          </div>
        </div>
        <!-- <div class="table-responsive">
          <table class="table card-table text-nowrap table-vcenter">
            <thead v-if="titles && titles.length > 0">
              <tr>
                <th width="10%" v-for="title in titles" :key="title">{{ title.replace(/_/g, " ") }}</th>
              </tr>
            </thead>
            <tbody v-loading="isLoading">
              <tr v-for="item in list" :key="item.id">
                <td v-for="title in titles" :key="title">{{ item[title] }}</td>
              </tr>
            </tbody>
          </table>
          <el-empty description="No data found" v-if="(!list || list.length === 0) && !isLoading" :image="emptyStateImage"></el-empty>
        </div> -->
        <div class="table-responsive">
          <table class="table card-table text-nowrap table-vcenter">
            <thead>
              <tr>
                <th width="25%">INVOICE NUMBER</th>
                <th width="25%">TRANSACTION TIME</th>
                <th width="20%">ORDER DETAIL ID</th>
                <th width="20%">SOURCE ID</th>
                <th width="10%">RANK ORDER</th>
                <th width="10%">VENDOR NAME</th>
                <th width="10%">PRODUCT NAME</th>
                <th width="10%">PRODUCT TYPE</th>
                <th width="10%">SUB PRODUCT TYPE</th>
                <th width="10%">PAYMENT STATUS</th>
                <th width="10%">PRODUCT STATUS</th>
                <th width="10%">PAYMENT NAME</th>
                <th width="10%">PAYMENT VENDOR</th>
                <th width="10%">REF ID</th>
                <th width="10%">REF STATUS</th>
                <th width="10%">QUANTITY</th>
                <th width="10%">COS</th>
                <th width="10%">TOTAL COS</th>
                <th width="10%">SELLING PRICE</th>
                <th width="10%">TOTAL SELLING PRICE</th>
                <th width="10%">REVENUE PLATFORM FEE</th>
                <th width="10%">DISCOUNT</th>
                <th width="10%">CASHBACK USED</th>
                <th width="10%">ALLOWANCE COIN USED</th>
                <th width="10%">MAIN COIN USED</th>
                <th width="10%">CUSTOMER PAY</th>
                <th>MDR COST</th>
              </tr>
            </thead>
            <tbody v-loading="isLoading">
              <tr v-for="(item, i) in list" :key="i">
                <td>{{ item.invoice_number }}</td>
                <td>{{ item.transaction_time }}</td>
                <td>{{ item.order_detail_id }}</td>
                <td>{{ item.source_id }}</td>
                <td>{{ item.rank_order }}</td>
                <td>{{ item.vendor_name }}</td>
                <td>{{ item.product_name }}</td>
                <td>{{ item.product_type }}</td>
                <td>{{ item.sub_product_type }}</td>
                <td>{{ item.payment_status }}</td>
                <td>{{ item.product_status }}</td>
                <td>{{ item.payment_name }}</td>
                <td>{{ item.payment_vendor }}</td>
                <td>{{ item.ref_id }}</td>
                <td>{{ item.ref_status }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.cos }}</td>
                <td>{{ item.total_cos }}</td>
                <td>{{ item.selling_price }}</td>
                <td>{{ item.total_selling_price }}</td>
                <td>{{ item.revenue_platform_fee }}</td>
                <td>{{ item.discount }}</td>
                <td>{{ item.cashback_used }}</td>
                <td>{{ item.allowance_coin_used }}</td>
                <td>{{ item.main_coin_used }}</td>
                <td>{{ item.customer_pay }}</td>
                <td>{{ item.mdr_cost }}</td>
              </tr>
            </tbody>
          </table>
          <!-- <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty> -->
           <el-empty description="No data found" v-if="list.length == 0" :image="emptyStateImage"></el-empty>
        </div>
        <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
          <div class="inline-table">
            <b-pagination
              class="table-cell"
              @change="pageChangeHandler"
              :per-page="per_page"
              :limit="5"
              :total-rows="total_rows"
              v-model="page"
            />
          </div>
        </div>
      </div>
      <b-modal size="lg" v-model="showDetailTransaction" :title="'Transaction #' + selected_transaction.invoice_number" hide-footer>
        <el-skeleton :loading="loading_detail" :rows="6" animated />
        <table v-if="!loading_detail" class="table table-vcenter border-0 w-full">
          <tr>
            <td class="p-2">Transaction Date</td>
            <td>{{ selected_transaction.created_at_str }}</td>
          </tr>
          <tr>
            <td class="p-2">Invoice Number</td>
            <td>{{ selected_transaction.invoice_number }}</td>
          </tr>
          <tr>
            <td class="p-2">User Name</td>
            <td>{{ selected_transaction.user.full_name }}</td>
          </tr>
          <tr>
            <td class="p-2">User Email</td>
            <td>{{ selected_transaction.user.email }}</td>
          </tr>
          <tr>
            <td class="p-2">User Phone Number</td>
            <td>{{ selected_transaction.user.phone_number }}</td>
          </tr>
          <tr>
            <td class="p-2">Payment Vendor</td>
            <td>{{ selected_transaction.payment_vendor_str }}</td>
          </tr>
          <tr>
            <td class="p-2">Payment Status</td>
            <td class="text-nowrap text-muted"><span class="tag" :class="selected_transaction.status_color_str">{{ selected_transaction.status_str }}</span></td>
          </tr>
        </table>
        <div class="table-responsive">
          <table v-if="!loading_detail" class="table table-vcenter w-full">
            <thead>
              <tr>
                <th width="40%">Product Name</th>
                <th>Type</th>
                <th>Qty</th>
                <th>Base Price</th>
                <th>Sell Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in selected_transaction.details" :key="i">
                <td>{{ item.product.name }}</td>
                <td>{{ item.source_type_str }}</td>
                <td>{{ item.product.quantity }}</td>
                <td>{{ item.product.base_price }}</td>
                <td>{{ item.product.sell_price }}</td>
                <td>{{ item.product.total_price }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr v-for="(item, i) in selected_transaction.detail_discount" :key="i">
                <td colspan="5"><i>{{ item.promo_name }}</i></td>
                <td><i>-{{ item.promo_discount_str }}</i></td>
              </tr>
              <tr style="background-color: #f4f6fa; font-weight: bold;">
                <td colspan="5">Subtotal</td>
                <td>{{ selected_transaction.amount_str }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </b-modal>
    </div>
</template>
<script>
import moment from 'moment';
// import { upperFirst } from 'lodash';
import {
  GET_FINANCE_TRANSACTION, DOWNLOAD_FINANCE_TRANSACTION, GET_VENDOR_BALANCE_FINANCE,
} from '@/store/modules/transactions';
import emptyStateImage from '@/assets/images/empty-state.png';

export default {
  name: 'Transactions',
  metaInfo: {
    title: 'Transactions',
  },
  data() {
    return {
      showDetailTransaction: false,
      search_options: [
        { value: 'full_name', text: 'Full Name' },
        { value: 'email', text: 'Email' },
        { value: 'phone_number', text: 'Phone Number' },
      ],
      emptyStateImage,
      merchantList: [],
      merchant_id: '',
      filter_merchant_loading: false,
      per_page: 20,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      loading_detail: false,
      loader: null,
      search_keyword: '',
      search_by: '',
      search_merchant_keyword: '',
      selected_transaction: {
        user: {},
      },
      loading: {
        summary_vendor: false,
      },
      search: {
        date: [
          moment().subtract(30, 'day').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        search_by: 'full_name',
        search_keyword: '',
        start_date: moment().subtract(30, 'day').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        product_status: '',
      },
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), 'days'),
      },
      summary_vendor: [],
      vendor_filter: {
        'ultra-voucher': 4,
        'mobile-pulsa': 1,
        e2pay: 8,
        rajabiller: 12,
      },
      vendor_name: {
        'ultra-voucher': 'Ultra Voucher',
        'mobile-pulsa': 'Mobile Pulsa',
        e2pay: 'E2Pay',
        rajabiller: 'Raja Biller',
      },
      status_options: [
        {
          label: 'Success',
          value: '',
        },
        {
          label: 'All',
          value: 'all',
        },
      ],
      current_balance: null,
      balances_list: [],
      titles: [],
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    vendorName() {
      return this.$route.params.vendor ? this.vendor_name[this.$route.params.vendor] : '';
    },
  },
  async mounted() {
    console.log('this.$route.params.vendor', this.$route.params.vendor);
    const vendor_id = this.vendor_filter[this.$route.params.vendor];
    // if (vendor_id) {
    //   this.search.search_by = 'vendor';
    //   // this.search.search_keyword = vendor_id;
    //   this.search.search_keyword = this.$route.params.vendor;
    // }
    if(this.$route.params.vendor){
      this.search.search_by = 'product';
      this.search.search_keyword = this.$route.params.vendor;
    }else{
      this.search.search_by = '';
    }
    
    const loader = this.$loading.show();
    await this.getList();
    if (!vendor_id) {
      this.getBalance('ultra-voucher');
      this.getBalance('mobile-pulsa');
      //this.getBalance('e2pay');
    } else {
      this.getBalance(this.$route.params.vendor);
    }
    loader.hide();
  },
  methods: {
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler(data) {
      this.page = 1;
      this.search.start_date = moment(this.search.date[0]).format('YYYY-MM-DD');
      this.search.end_date = moment(this.search.date[1]).format('YYYY-MM-DD');
      this.getList();
    },
    searchMerchant(query) {
      this.search_merchant_keyword = query;
      this.getMerchantList();
    },
    async getList() {
      this.isLoading = true;
      await this.$store.dispatch(GET_FINANCE_TRANSACTION, {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search.search_by,
        search_keyword: this.search.search_keyword,
        ...this.search,
      }).catch(() => {});
      const { rows, count } = this.$store.getters.finance_trx;
      const titles = this.$store.getters.titles;
      this.total_rows = count || 0;
      this.list = rows;
      this.isLoading = false;
      this.titles = titles;
      console.log('list transaction', rows);
    },
    async downloadHandler() {
      this.isLoading = true;
      await this.$store.dispatch(DOWNLOAD_FINANCE_TRANSACTION, {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search_by,
        search_keyword: this.search_keyword,
        product_status: 'all',
        ...this.search,
      }).then(() => {
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Please check your email regularly for downloaded file.',
        });
        // const anchor = document.createElement('a');
        // anchor.href = `data:${result.headers['content-type']},${encodeURIComponent(result.data)}`;
        // anchor.target = '_blank';
        // anchor.download = `Finance-Report-${this.search.start_date}-${this.search.end_date}.csv`;
        // anchor.click();
      }).catch(() => {});
      this.isLoading = false;
    },
    getBalance(vendor = '') {
      this.$store.dispatch(GET_VENDOR_BALANCE_FINANCE, {
        vendor: (vendor || this.$route.params.vendor).replace(/-/g, '_'),
      }).then(({ data }) => {
        if (data.balance) {
          this.balances_list.push({
            vendorName: this.vendor_name[vendor],
            current_balance: `Rp.${data.balance.toLocaleString()}`,
            loading: false,
            vendor,
          });
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    refreshBalance(item, index) {
      const { vendor } = item;
      this.balances_list[index].loading = true;
      this.$store.dispatch(GET_VENDOR_BALANCE_FINANCE, {
        vendor: item.vendor.replace(/-/g, '_'),
      }).then(({ data }) => {
        this.$set(this.balances_list, index, {
          vendorName: this.vendor_name[vendor],
          current_balance: `Rp.${data.balance.toLocaleString()}`,
          loading: false,
          vendor,
        });
      }).catch(() => {});
    },
  },
};
</script>
<style>
.el-range-separator {
  width: 10% !important;
}
.child1-sticky
{
  position:sticky;
  right: 0px;
  width: 40px;
  background: #fff;
}
.child2-sticky
{
  position:sticky;
  right: 69px;
  width: 255px;
  background: #fff;
}
</style>
